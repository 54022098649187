@import "reset.scss";
@import "defaults.scss";
/*
  Issue with semantic-ui-css 2.3.0
  https://github.com/Semantic-Org/Semantic-UI-CSS/issues/40
*/
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
  a {
    color: $textColor;
    outline: none;
    &:hover {
      color: $textColor;
    }
  }
  button,
  select,
  input {
    font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
  }
}
.container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  background-color: #1d2429;
}
input::-ms-clear {
  display: none;
}
.ui.dimmer {
  z-index: 91 !important;
}
.modals.dimmer[class*="top aligned"] {
  padding: 50px !important;
}
.modals.dimmer[class*="top aligned"] .modal {
  margin: 0 auto !important;
}
.ui.top.modals.dimmer {
  overflow: auto;
  padding: 0px !important;
  .ui.modal {
    margin: 50px 0px !important;
  }
}
:global {
  .scrollbar-container {
    .ps__rail-y {
      z-index: 1;
    }
  }
}
