@import 'defaults.scss';

.app {
  position: absolute;
  overflow: hidden;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #1d2429;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100%;
  }
  .wrapper {
    height: 100%;
    width: 100%;
    padding: 65px;
    overflow: auto;
    .container {
      background-color: #fff;
      border-radius: 2px;
      min-height: 700px;
      width: 1130px;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: row;
      margin: auto;
    }
  }
}
