/*
  Fonts
*/

$light: 300;
$regular: 400;
$bold: 600;

$AMServiceYellow: #eec900;
$AMServiceBlue: #0077ef;
$AMServiceBlueHover: #0c66c2;
$AMServiceBlack: #26292b;
$AMServiceGreen: #00a613;
$AMServiceLightGrey: #f3f3f3;
$AMServiceDarkGrey: #313436;

$white: #fff;
$textColor: #515151;
$textHoverColor: #4f4f4f;
$lightTextColor: #838383;
$lightTextHoverColor: #616161;
$iconColor: #747474;
$borderOnWhite: #dddddd;
$mainColor: #4c9ac8;
$mainColorHover: #3c83ac;
$newColor: #e7f5fd;
$newBorder: #d0dde4;
$newColorHover: #d9eaf5;
$red: #c55050;
$redHover: #a73838;
$green: #2baf2d;
$hoverOnWhite: #f4f4f4;
$fieldPlaceHolderColor: #c2c2c2;
$orange: #f07f39;
$warning: #e68d1b;

$lightBlue: #e2eff6;
$lightBlueHover: #d6e8f2;
$lightRed: #fad5d5;
$lightRedHover: #f7caca;
$lightGreen: #c2efc7;
$lightOrange: #fce5d7;
$lightGray: #f0f0f0;
$lightBlueBorder: #d3dce1;
$lightRedBorder: #edc7c7;
$lightGreenBorder: #a7dead;
$lightOrangeBorder: #f1d2bf;
$lightGrayHover: #ebebeb;

$fieldBackground: #f4f8fa;
$fieldBorder: #d8dbdd;
$fieldBorderFocus: #9ec5de;
$fieldIconColor: #979c9f;
$fieldErrorBackground: #faf4f4;
$fieldErrorBorder: #e59595;

@mixin transition_hover() {
  -webkit-transition: background-color 80ms ease-out;
  -moz-transition: background-color 80ms ease-out;
  -o-transition: background-color 80ms ease-out;
  transition: background-color 80ms ease-out;
}

@mixin ignore_select() {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
